import React, { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
// import { useQuery } from '@apollo/client';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import BallotRoundedIcon from '@material-ui/icons/BallotRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SmsRoundedIcon from '@material-ui/icons/SmsRounded';
import { navigate } from 'gatsby';

import { companyMenuAction } from '../../reducers/companyMenuReducer';

enum Status {
  NONE = ' 无',
  APPLIED = '已发布',
  ACCEPTED = '已签约',
  DENIED = '已拒绝',
  PAID = '已支付',
  IN_PROGRESS = '处理中',
  DONE = '已完成',
  ARCHIVED = '已过期',
  DELETED = '已删除',
}
Status;
const styles = (): StyleRules =>
  createStyles({
    contentPadding: {
      height: '100%',
      padding: '1rem',
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'auto',
    },
    childrenItem: {
      cursor: 'pointer',
      background: '#ffffff',
      boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
      borderRadius: '3px',
      height: '30%',
      width: '30%',
      margin: '1rem',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.8rem',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        background: '#deefef',
      },
    },
    title: {
      margin: '20px 10px',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '2rem',
    },
    content: {
      height: '80vh',
      overflow: 'auto',
      padding: '20px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      overflowY: 'scroll',
    },
  });

const indexView = ({ classes }: Props): ReactElement => {
  // const user = useSelector((appState: AppState) => appState.user);
  // const company = useSelector((appState: AppState) => appState.company);
  const dispatch = useDispatch();
  useEffect(() => {
    // if (user.status == 'NONE' || !user.status) {
    //   if (company.verification?.status === 'NONE' || !company.verification?.status) {
    //     navigate('/company/verification');
    //   }
    // }
    dispatch(
      companyMenuAction({
        menuStatus: {
          home: true,
          publishProject: false,
          projectList: false,
          biddingList: false,
          pendingProject: false,
        },
      })
    );
  }, []);

  return (
    <div className={classes.content}>
      <Helmet title="首页"></Helmet>

      <div className={classes.title}>企业管理中心</div>
      <div className={classes.contentPadding}>
        <div
          className={classes.childrenItem}
          onClick={() => {
            navigate('/company/publishProject');
          }}
        >
          <LibraryAddIcon className={classes.iconMargin} />
          <span>发布项目</span>
        </div>
        <div
          className={classes.childrenItem}
          onClick={() => {
            navigate('/company/projectList');
          }}
        >
          <LibraryBooksIcon className={classes.iconMargin} />
          <span>项目列表</span>
        </div>
        <div
          className={classes.childrenItem}
          onClick={() => {
            navigate('/company/bidding');
          }}
        >
          <AssignmentRoundedIcon className={classes.iconMargin} />
          <span>申请浏览 </span>
        </div>
        <div
          className={classes.childrenItem}
          onClick={() => {
            navigate('/company/pendingProject');
          }}
        >
          <BallotRoundedIcon className={classes.iconMargin} />
          <span>进行中项目</span>
        </div>
        <div className={classes.childrenItem}>
          <CreditCardRoundedIcon className={classes.iconMargin} />
          <span>项目结算</span>
        </div>
        <div className={classes.childrenItem}>
          <SmsRoundedIcon className={classes.iconMargin} />
          <span>项目评价</span>
        </div>
      </div>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(indexView);
